import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Root', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  h1,h2,h3,h4{
      font-family: 'RootBold', sans-serif;

  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 768px) {
    .container {
      padding: 1rem;
    }
  }

  @media (min-width: 769px) {
    .container {
      padding: 0 2rem;
    }
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: flex-end;
  padding: 1rem 2rem;
  top: 0;
  z-index: 10;

  a {
    margin: 0 1rem;
    font-weight: 700;
  }
`;

export const Section = styled.section`
  .card-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .card-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .card {
    position: relative;
    overflow: hidden;
    margin: 0.5rem;
    background: #fff;
    padding: 1rem;
    border-radius: 20px;
    aspect-ratio: 0.9; /* Ensure square aspect ratio */
    width: calc(50% - 1rem); /* Adjust width for spacing */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.1s ease;

    @media (max-width: 768px) {
      width: calc(100% - 1rem); /* Full width on small screens */
      aspect-ratio: 0.6; /* Ensure square aspect ratio */

    }

    &.profile {
      width: 100%;
      height: auto; /* Adjust height to maintain aspect ratio */
    }

    &.fullWidth {
      width: 100%;
    }
  }

  .card-photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
      }   
    @media (max-width: 768px) {
      &:hover {
      transform: scale(1.04);
      }    
    }
  }

  .card-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.5rem;
    color: black;
    z-index: 1;
    text-align: left;
    font-size: 16px;
    display: flex;
    column-gap: 3rem;
    flex-direction: row;

    @media (max-width: 768px) {
      font-size: 11px;
          padding: 1rem;
          padding-right:1.5rem;
        column-gap: 2rem;

    }

    p {
      margin: 0.2rem 0; /* Reduce margin for <p> elements */
    }
    .bold {
      font-family: 'RootBold', sans-serif;
      font-size: 20px;

    @media (max-width: 768px) {
      font-size: 13px;


    }
    }
  }
`;


export const Footer = styled.footer`
  text-align: center;
  padding: 1rem;
  background: #333;
  color: #fff;
`;

export const ResetButton = styled.a`
  display: block;
  margin: 1rem;
  height: 20px;

  .fa-arrow-up {
    font-size: 20px;
  }
`;

export const ExitLink = styled.a`
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1.5rem;
      @media (max-width: 768px) {
  margin: 1rem;
    }
`;
