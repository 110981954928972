// src/components/FitForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { Form, FormGroup, Label, Select, Button, Result } from "./FitFormStyles"

const FitForm = ({ onClose }) => {
    const [experience, setExperience] = useState('');
    const [growth, setGrowth] = useState('');
    const [skills, setSkills] = useState('');
    const [result, setResult] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:4000/api/check-fit', { // Ensure this URL matches your server port
                experience,
                growth,
                skills,
            });

            setResult(response.data.result);
        } catch (error) {
            console.error('Error submitting form:', error);
            setResult("I have to tinker with OpenAI, you can just contact me instead!");

        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label htmlFor="experience">How much experience do you need from the developer?</Label>
                <Select id="experience" value={experience} onChange={(e) => setExperience(e.target.value)}>
                    <option value="">Select...</option>
                    <option value="3-5 years of development experience">3-5 years of development experience</option>
                    <option value="over 6 years experience">Over 6 years of development experience</option>
                    <option value="1-3 years experience">1-3 years experience</option>
                    <option value="years of experience doesn't matter">Years of experience doesn't matter</option>
                </Select>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="growth">Are you looking for someone seeking (often uncomfortable) growth and deepening craft expertise?</Label>
                <Select id="growth" value={growth} onChange={(e) => setGrowth(e.target.value)}>
                    <option value="">Select...</option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                </Select>
            </FormGroup>
            <FormGroup>
                <Label htmlFor="skills">Are you looking for someone proficient in React, JavaScript, Node.js? Fluency in Python language? Familiarity with AWS services, GraphQL, .NET, Jenkins, Data Structures and Algorithms?</Label>
                <Select id="skills" value={skills} onChange={(e) => setSkills(e.target.value)}>
                    <option value="">Select...</option>
                    <option value="yes to all these skills">Yes to all of those</option>
                    <option value="yes to some of these skills">Yes to some</option>
                    <option value="do not want these skills">No, something completely different</option>
                </Select>
            </FormGroup>
            <Button type="submit">Submit</Button>
            {result && <Result>Here's the breakdown. {result}</Result>}
            <Button type="button" onClick={onClose}>Close</Button>
        </Form>
    );
};

export default FitForm;
