import styled from 'styled-components';

export const Card = styled.div`
    position: relative;
    overflow: hidden;
    margin: 0.5rem;
    background: ${(props) => props.background};
    padding: 1rem;
    border-radius: 20px;
    aspect-ratio: 1; /* Ensure square aspect ratio */
    width: calc(50% - 1rem); /* Adjust width for spacing */
    display: flex;
    align-items: center;
    jusify-content: center;
    transition: background 0.1s ease;
    justify-content: center;

    @media (max-width: 768px) {
      width: calc(100% - 1rem); /* Full width on small screens */
    }

    &.fullWidth {
      width: 100%;
      aspect-ratio: unset; /* Ensure square aspect ratio */
    }
`;

export const CardContent = styled.div`
  position: relative;
  z-index: 1;
  color: black;
  text-align: left;

  .social-icons {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction:column;
        row-gap: 1rem;
        align-items: flex-start;

    }
  }

  .social-icons a {
    margin-right: 1rem;
  }

  .contact-button {
    padding: 0.75rem 1.5rem;
    border: 1px solid black;
    border-radius: 25px;
    cursor: pointer;
    display: inline;
    color: #000;
  }

  .contact-button:hover {
    background: #F9F5F1;
    color: #000;
  }
`;
