// src/components/FitForm.js
import styled from 'styled-components';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  margin: 0 auto;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Label = styled.label`
  font-weight: bold;
`;

export const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 1rem;
`;

export const Result = styled.p`
  font-size: 1rem;
  color: #333;
  font-weight: 500;
`;


export const Button = styled.button`
    padding: 0.75rem 1.5rem;
    border: 1px solid black;
    border-radius: 25px;
    cursor: pointer;
    display: inline;
    color: #000;

    &:hover {
    background: #F9F5F1;
    color: #000;
  }
`;
