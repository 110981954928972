import React, { useState } from 'react';
import { Card, CardContent } from './GradientCardStyle';

const GradientCard = ({ children, fullWidth = false }) => {
    const [background, setBackground] = useState('radial-gradient(circle at top right, #CCD6D6, #F9F5F1)');

    const handleMouseMove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const xPercent = (x / rect.width) * 100;
        const yPercent = (y / rect.height) * 100;

        setBackground(`radial-gradient(circle at ${xPercent}% ${yPercent}%, #CCD6D6, #F9F5F1)`);
    };

    const handleMouseLeave = () => {
        setBackground('radial-gradient(circle at top right, #CCD6D6, #F9F5F1)');
    };

    return (
        <Card
            background={background}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            className={fullWidth ? 'fullWidth' : ''}
        >
            <CardContent>
                {children} {/* This should render the JSX content passed as children */}
            </CardContent>
        </Card>
    );
};

export default GradientCard;
