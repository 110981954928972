// src/components/Modal.js
import React from 'react';
import { ModalOverlay, ModalContainer, CloseButton } from './ModalStyles';

const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
        <ModalOverlay>
            <ModalContainer>
                <CloseButton onClick={onClose}>X</CloseButton>
                {children}
            </ModalContainer>
        </ModalOverlay>
    );
};

export default Modal;
